<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h3>รายการสั่งซื้อ ลาวพัดทะนา</h3>
      </v-col>
      <!-- <v-col cols="3">
        <span>ค้นหาด้วยชื่อ</span>
        <v-text-field
          label=""
          v-model="receiveFirstName"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <span>ค้นหาด้วยนามสกุล</span>
        <v-text-field
          label=""
          v-model="receiveLastName"
          outlined
          dense
        ></v-text-field>
      </v-col> -->
      <v-col cols="4">
        <v-row align="center" justify="center">
          <v-col cols="12">
            <span>งวดที่ออก</span>
            <v-menu
              v-model="menufrom"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="drawDate"
                  label="วันที่ออก"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  solo
                  clearable
                  @click:clear="drawDate = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="drawDate"
                @input="menufrom = false"
                @click="$refs.menufrom.save(drawDate)"
              ></v-date-picker>
              <!-- <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="lotteryDate"
                        label="วันที่ออก"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        solo
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="lotteryDate"
                      no-title
                      scrollable
                      :min="firstDate"
                    >
                      <v-btn text color="primary" @click="menufrom = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menufrom.save(lotteryDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker> -->
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn @click="clear()" class="mr-4">เคลียร์</v-btn>
        <v-btn @click="getAllOrder()" color="primary">ค้นหา</v-btn>
      </v-col>
      <br />
      <v-tabs centered v-model="keytab">
        <v-tab v-for="item in tabitems" :key="item.key" @click="tab(item)">{{
          item.text
        }}</v-tab>
      </v-tabs>

      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <ExportOrderLao :data="items" />
          <!-- <v-col class="text-right">
            <v-btn @click="goToCreate()" color="primary" dark
              >เพิ่มหมวดหมู่</v-btn
            >
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersOrder"
            :items="items"
            :search="search"
            :items-per-page="50"
            class="elevation-1"
          >
            <template v-slot:[`item.transactionNo`]="{ item }">
              <span style="color: red" v-if="item.refundPrice">{{
                item.transactionNo
              }}</span>
              <span v-else>{{ item.transactionNo }}</span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              <span>{{ convertDate(item.createdAt) }}น.</span>
            </template>
            <template v-slot:[`item.selectedToStr`]="{ item }">
              <v-row class="py-4">
                <span>{{ item.selectedToStr }}</span>
              </v-row>
            </template>
            <!-- <template v-slot:[`item.name`]="{ item }">
              <span> {{ item.user.firstName }} {{ item.user.lastName }} </span>
            </template> -->
            <!-- <template v-slot:[`item.isDelivery`]="{ item }">
              <span v-if="item.isDelivery">จัดส่งตามที่อยู่</span>
              <span v-if="!item.isDelivery">เก็บในตู้เซพ</span>
            </template> -->

            <template v-slot:[`item.status`]="{ item }">
              <v-chip v-if="item.status == 'รอคอนเฟิร์ม'" color="orange" dark>
                รอยืนยันสลิป
              </v-chip>
              <!-- <v-chip
                v-if="item.status == 'ยืนยันสลิปสำเร็จ'"
                color="green"
                dark
              >
                ยืนยันสลิปสำเร็จ
              </v-chip>
              <v-chip
                v-if="item.status == 'ยังไม่ได้อัพโหลดสลิป'"
                color="blue"
                dark
              >
                ยังไม่ได้อัพโหลดสลิป
              </v-chip> -->
              <v-chip v-if="item.status == 'สั่งซื้อสำเร็จ'" color="green" dark>
                สั่งซื้อสำเร็จ
              </v-chip>
              <v-chip v-if="item.status == 'รอการชำระเงิน'" color="red" dark>
                รอการชำระเงิน
              </v-chip>
              <v-chip
                v-if="item.status == 'ชำระเงินสำเร็จ'"
                color="orange"
                dark
              >
                ชำระเงินสำเร็จ
              </v-chip>
              <v-chip v-if="item.status == 'มีปัญหา'" color="red" dark>
                มีปัญหา
              </v-chip>
              <v-chip v-if="item.status == 'ยกเลิก'" color="brown" dark>
                ยกเลิก
              </v-chip>
              <!-- <v-chip v-if="item.status == 'ถูกจัดส่งแล้ว'" color="green" dark
                ><v-icon>mdi-check-circle</v-icon>{{ item.status }}</v-chip
              > -->
            </template>
            <template v-slot:[`item.buyToFlag`]="{ item }">
              <span v-if="item.buyToFlag == 1">ใช่</span>
              <span v-else>ไม่ใช่</span>
            </template>
            <template v-slot:[`item.agentId`]="{ item }">
              <span v-if="item.agentId">ใช่</span>
              <span v-else>ไม่ใช่</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <!-- <v-icon @click="viewOrder(item)">mdi-eye</v-icon> -->
                <v-icon @click="UpdateOrder(item)" class="mx-2"
                  >mdi-pencil</v-icon
                >
                <!-- <v-icon @click="DeleteOrder(item)">mdi-delete</v-icon> -->
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import moment from "moment";
import { Decode, Encode } from "@/services";
import ExportOrderLao from "@/components/ExportExcel/ExportOrderLao";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: { ExportOrderLao, Loading },
  data() {
    return {
      keytab: 0,
      loading: false,
      headersOrder: [
        // ลำดับ หมายเลขคำสั่งซื้อ สถานะ ตัวเลือก วันที่สั่ง ชื่อผู้สั่ง หมายเลขโทรศัพท์ ราคา เลขที่ซื้อ  ออเดอร์ตัวแทน รับผิดชอบโดย
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "หมายเลขคำสั่งซื้อ", value: "transactionNo", align: "center" },
        { text: "สถานะ", value: "status", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
        { text: "วันที่สั่ง", value: "createdAt", align: "center" },
        { text: "ชื่อผู้สั่ง", value: "fullname", align: "center" },
        { text: "หมายเลขโทรศัพท์", value: "user.telNumber", align: "center" },
        { text: "ราคา", value: "totalPrice", align: "center" },
        {
          text: "สลากที่เลือก",
          value: "selectedToStr",
          align: "center",
          width: "80px",
        },
        { text: "ยอดเงินคืน", value: "refundPrice", align: "center" },
        { text: "ออเดอร์ตัวแทน", value: "agentId", align: "center" },
        // { text: "เลขที่ซื้อ", value: "buyLotNo", align: "center" },
        // { text: "สถานะการจัดส่ง", value: "isDelivery", align: "center" },
        { text: "ซื้อให้คนอื่น", value: "buyToFlag", align: "center" },
        { text: "รับผิดชอบโดย", value: "adminName", align: "center" },
      ],
      // count: 1
      // couponCode: (...)
      // createdAt: (...)
      // deliveryAddress: (...)
      // deliveryStatus: (...)
      // id: (...)
      // isDelivery: (...)
      // lotteryDate: (...)
      // lotteryTransaction: (...)
      // slipImg: (...)
      // status: (...)
      // totalPrice: (...)
      // transactionNo: (...)
      // updatedAt: (...)
      // user: Object
      // userId: (...)
      items: [
        // { category: "promotion", description: "สินค้าจัดโปร" },
        // { category: "discount items", description: "สินค้าลดราคา" },
        // { category: "Shock price", description: "สินค้าราคาถูก" },
        // { category: "สินค้ามาใหม่", description: "สินค้ามาใหม่" },
      ],
      tabitems: [
        { text: "ทั้งหมด", key: "", index: 0 },
        { text: "รอยืนยันสลิป", key: "รอคอนเฟิร์ม", index: 1 },

        { text: "รอการชำระเงิน", key: "รอการชำระเงิน", index: 2 },
        { text: "ชำระเงินสำเร็จ", key: "ชำระเงินสำเร็จ", index: 3 },
        { text: "สั่งซื้อสำเร็จ", key: "สั่งซื้อสำเร็จ", index: 4 },
        { text: "มีปัญหา", key: "มีปัญหา", index: 5 },
        { text: "ยกเลิก", key: "ยกเลิก", index: 6 },
      ],
      search: "",
      count: 0,
      menufrom: false,
      drawDate: "",
      receiveFirstName: "",
      receiveLastName: "",
      selectedindex: 0,
    };
  },
  created() {
    this.keytab = parseInt(this.$route.query.tab ? this.$route.query.tab : 0);

    console.log("keytabbefore", this.keytab);

    this.getData();
  },
  methods: {
    async getData() {
      let keytab = 0;
      if (this.keytab == 0) {
        keytab = "";
      } else if (this.keytab == 1) {
        keytab = "รอคอนเฟิร์ม";
      } else if (this.keytab == 2) {
        keytab = "รอการชำระเงิน";
      } else if (this.keytab == 3) {
        keytab = "ชำระเงินสำเร็จ";
      } else if (this.keytab == 4) {
        keytab = "สั่งซื้อสำเร็จ";
      } else if (this.keytab == 5) {
        keytab = "มีปัญหา";
      } else if (this.keytab == 6) {
        keytab = "ยกเลิก";
      }
      if (this.keytab == "") {
        this.getAllOrder();
      } else {
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("DDLotAdmin"))
        );
        console.log(user);
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/transaction/getAllLaoTrx?status=` +
            keytab +
            // "&customerName=" +
            // this.customerName +
            "&drawDate=" +
            this.drawDate,
          auth
        );
        this.items = response.data.data;
        for (let i in this.items) {
          this.items[i].count = parseInt(i) + 1;
          this.items[i].fullname =
            this.items[i].user.firstName + " " + this.items[i].user.lastName;

          this.items[i].seletedSetObj = JSON.parse(this.items[i].selectedBall);
          // console.log(this.items[i].seletedSetObj);
          let selectedToStr = "";
          for (
            let index = 0;
            index < this.items[i].seletedSetObj.length;
            index++
          ) {
            const element = this.items[i].seletedSetObj[index];
            selectedToStr += `[` + element.set + ` *${element.powerBall}*]\n`;
          }

          // console.log(selectedToStr);
          this.items[i].selectedToStr = selectedToStr;
        }
        // console.log("response", response.data.data);
      }
    },
    clear() {
      this.drawDate = "";
      this.receiveFirstName = "";
      this.receiveLastName = "";
      this.getAllOrder();
    },
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY HH:mm");
    },
    async tab(val) {
      if (val) {
        this.selectedindex = val.index;
      }
      // console.log(val.key);
      // /orders?status=รอการยืนยัน
      if (val.key == "") {
        this.getAllOrder();
      } else {
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("DDLotAdmin"))
        );
        console.log(user);
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/transaction/getAllLaoTrx?status=${val.key}&receiveFirstName=${this.receiveFirstName}&receiveLastName=${this.receiveLastName}&drawDate=${this.drawDate}`,
          auth
        );
        this.items = response.data.data;
        console.log(this.items);
        for (let i in this.items) {
          this.items[i].count = parseInt(i) + 1;
          this.items[i].fullname =
            this.items[i].user.firstName + " " + this.items[i].user.lastName;

          this.items[i].seletedSetObj = JSON.parse(
            this.items[i].selectedNumber
          );
          console.log(this.items[i].seletedSetObj);
          let selectedToStr = "";
          for (
            let index = 0;
            index < this.items[i].seletedSetObj.length;
            index++
          ) {
            const element = this.items[i].seletedSetObj[index];
            selectedToStr += `[` + element.set + `]\n`;
          }

          console.log(selectedToStr);
          this.items[i].selectedToStr = selectedToStr;
        }
        console.log("response", response.data.data);
      }
    },
    async getAllOrder() {
      console.log("เข้าเข้าเข้า");
      this.loading = true;
      var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      console.log(user);
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/getAllLaoTrx?receiveFirstName=${this.receiveFirstName}&receiveLastName=${this.receiveLastName}&drawDate=${this.drawDate}`,
        auth
        // 'http://localhost:8081/categories/'
      );
      console.log("getAllOrder", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
        this.items[i].fullname =
          this.items[i].user.firstName + " " + this.items[i].user.lastName;

        this.items[i].seletedSetObj = JSON.parse(this.items[i].selectedNumber);
        console.log(this.items[i].seletedSetObj);
        let selectedToStr = "";
        for (
          let index = 0;
          index < this.items[i].seletedSetObj.length;
          index++
        ) {
          const element = this.items[i].seletedSetObj[index];
          selectedToStr += `[` + element.set + `]\n`;
        }

        console.log(selectedToStr);
        this.items[i].selectedToStr = selectedToStr;

        // this.items[i].buyLotNo = "";
      }

      console.log(this.items);
      this.loading = false;
    },
    UpdateOrder(val) {
      localStorage.setItem("orderdata", Encode.encode(val));
      this.$router.push({
        path: "/EditLaoOrder",
        query: {
          tab: this.selectedindex,
        },
      });
    },
    async DeleteOrder(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("DDLotAdmin"))
          );
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/transaction/` + val.id,
            auth
          );
          console.log(response);
          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllOrder();
        }
      });
    },
    goToCreate() {
      this.$router.push("createcategory");
    },
  },
};
</script>
