<template>
  <div>
    <ManageLaoOrder />
  </div>
</template>
<script>
import ManageLaoOrder from "@/components/Order/ManageLaoOrder";
export default {
  components: {
    ManageLaoOrder,
  },
  created() {
  },
};
</script>